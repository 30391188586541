// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dziennik-budowy-js": () => import("./../src/pages/dziennik-budowy.js" /* webpackChunkName: "component---src-pages-dziennik-budowy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-mieszkania-js": () => import("./../src/pages/mieszkania.js" /* webpackChunkName: "component---src-pages-mieszkania-js" */),
  "component---src-pages-o-inwestycji-js": () => import("./../src/pages/o-inwestycji.js" /* webpackChunkName: "component---src-pages-o-inwestycji-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */)
}

